<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="15">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="15">
              <a-form-item label="分类" prop="type">
                <a-select
                  :v-model="queryParam.type"
                  style="width: 100%"
                  placeholder="请选择分类"
                  :options="this.categoryOptions"
                  @change="categoryHandlerChange"
                  allowClear
                ></a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="5" :sm="15">
                <a-form-item label="样式" prop="style">
                  <a-select placeholder="请选择样式" style="width: 250px" v-model="queryParam.style" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.SelectEnum" :value="item.style"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>-->
              <!--启用状态-->
              <a-col :md="5" :sm="15">
                <a-form-item label="启用状态" prop="isEnable">
                  <a-select
                    v-model="queryParam.isEnable"
                    style="width: 100%"
                    placeholder="请选择启用状态"
                    :options="this.customDictOptions"
                    @change="selectHandleChange"
                    allowClear
                  ></a-select>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="15">
                <a-form-item label="榜单展示数量" prop="showNum">
                  <a-input v-model="queryParam.showNum" placeholder="请输入榜单展示数量" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="15">
                <a-form-item label="属性值" prop="property">
                  <a-input v-model="queryParam.property" placeholder="请输入属性值" allow-clear/>
                </a-form-item>
              </a-col>
<!--              <a-col :md="5" :sm="15">-->
<!--                <a-form-item label="颜色,rgb编码" prop="color">-->
<!--                  <a-input v-model="queryParam.color" placeholder="请输入颜色,rgb编码" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['script:script/filter:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['script:script/filter:edit']">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['script:script/filter:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['script:script/filter:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['script:script/filter:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['script:script/filter:edit']">
            <a-icon type="edit" />修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['script:script/filter:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['script:script/filter:remove']">
            <a-icon type="delete" />删除
          </a>-->
        </span>
        <span slot="type" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.FilterEnum" :value="record.type"/>
        </span>
        <span slot="style" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.SelectEnum" :value="record.style"/>
        </span>
        <span slot="isEnable" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（下架）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isEnable"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listScriptFilter, delScriptFilter } from '@/api/script/scriptFilter'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag'

export default {
  name: 'ScriptFilter',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      categoryOptions: [],
      customDictOptions: [],
      // 查询参数
      queryParam: {
        type: null,
        name: null,
        style: null,
        isEnable: null,
        showNum: null,
        property: null,
        color: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '筛选项类型',
          dataIndex: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        /* {
          title: '样式',
          dataIndex: 'style',
          ellipsis: true,
          scopedSlots: { customRender: 'style' },
          align: 'center'
        }, */
        {
          title: '启用状态',
          dataIndex: 'isEnable',
          ellipsis: true,
          scopedSlots: { customRender: 'isEnable' },
          align: 'center'
        },
        {
          title: '榜单展示数量',
          dataIndex: 'showNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '属性值',
          dataIndex: 'property',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '颜色,rgb编码',
        //   dataIndex: 'color',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: { ...mapGetters(['customDict'])
  },
  mounted() {
    var options = this.customDict.FilterEnum
    options.forEach(e => {
      this.categoryOptions.push({ label: e.name, value: e.type })
    })
    options = this.customDict.TFEnum
    options.forEach(e => {
      this.customDictOptions.push({ label: e.name, value: e.type === 'true' ? 1 : 0 })
    })
  },
  watch: {
  },
  methods: {
    /** 查询剧本筛选项列表 */
    getList () {
      this.loading = true
      listScriptFilter(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        name: undefined,
        style: undefined,
        isEnable: undefined,
        showNum: undefined,
        property: undefined,
        color: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delScriptFilter(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    categoryHandlerChange(value) {
      this.queryParam.type = value
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('script/script/filter/export', {
            ...that.queryParam
          }, `script/filter_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    selectHandleChange(value) {
      this.queryParam.isEnable = value
    }
  }
}
</script>
