<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="分类" prop="type">
        <a-select placeholder="请选择分类" style="width: 250px" v-model="form.type" allowClear>
          <a-select-option
            v-for="(item, index) in this.customDict.FilterEnum"
            :value="item.type"
            :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" style="width: 250px"/>
      </a-form-model-item>
<!--      <a-form-model-item label="样式" prop="style">-->
<!--        <a-select placeholder="请选择样式" style="width: 250px" v-model="form.style" allowClear>-->
<!--          <a-select-option v-for="(item, index) in this.customDict.SelectEnum" :value="item.type"-->
<!--                           :key="index">-->
<!--            {{ item.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="启用状态" prop="isEnable" >
              <a-select
                v-model="form.isEnable"
                style="width: 100%"
                placeholder="请选择启用状态"
                :options="this.customDictOptions"
                @change="selectHandleChange"
              ></a-select>
            </a-form-model-item>-->
      <a-form-model-item label="启用状态" prop="isEnable">
        <a-switch v-model="form.isEnable" />{{ form.isEnable ? '是' : '否' }}
      </a-form-model-item>
            <a-form-model-item label="榜单展示数量" prop="showNum" >
              <a-input v-model="form.showNum" placeholder="请输入榜单展示数量" style="width: 250px" />
            </a-form-model-item>
      <a-form-model-item label="属性值" prop="property">
        <a-input v-model="form.property" placeholder="请输入属性值" style="width: 250px"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="颜色,rgb编码" prop="color" >-->
      <!--        <a-input v-model="form.color" placeholder="请输入颜色,rgb编码" style="width: 250px"/>-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="备注" prop="remark" >-->
      <!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear style="width: 250px"/>-->
      <!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getScriptFilter, addScriptFilter, updateScriptFilter} from '@/api/script/scriptFilter'
import {mapGetters} from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        name: null,

        style: null,

        isEnable: null,

        showNum: null,

        property: null,

        color: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          {required: true, message: '筛选项类型不能为空', trigger: 'change'}
        ],

        name: [
          {required: true, message: '名称不能为空', trigger: 'blur'}
        ],

        style: [
          {required: true, message: '样式不能为空', trigger: 'blur'}
        ],

        isEnable: [
          {required: true, message: '启用状态不能为空', trigger: 'blur'}
        ],

        showNum: [
          {required: true, message: '榜单展示数量不能为空', trigger: 'blur'}
        ],

        property: [
          {required: true, message: '属性值不能为空', trigger: 'blur'}
        ]

      },
      customDictOptions: []
    }
  },
  filters: {},
  created() {

  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
    // var options = this.customDict.TFEnum
    // options.forEach(e => {
    //   this.customDictOptions.push({ label: e.name, value: e.type === 'true' ? 1 : 0 })
    // })
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        type: null,

        name: null,

        style: null,

        isEnable: null,

        showNum: null,

        property: null,

        color: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getScriptFilter(id).then(response => {
        this.form = response.data
        //this.form.isEnable = this.form.isEnable ? 1 : 0
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateScriptFilter(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addScriptFilter(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    selectHandleChange(value) {
      this.form.isEnable = value
    }
  }
}
</script>
